<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search "
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <div class='d-flexspabet'>
          <div>
            <el-button @click="showEdit=true" class="ele-btn-icon addbtn ele-form-actions" size="small"
                       v-if="permission.includes('sys:admin:add')">
              添加管理员
            </el-button>
          </div>
          <div class="d-flex">
            <el-form-item label="管理账号:" label-width="130px" class="w-300">
              <el-input v-model="table.where.manage_accounts" placeholder="请输入管理账号" clearable />
            </el-form-item>
            <el-form-item label="省:" label-width="40px" class="w-150">
              <el-select v-model="table.where.pid" @change="handleChangeProv1(table.where.pid)" clearable>
                <el-option v-for="option in provArr" :label='option.name' :value="option.pid"
                           :key="option.pid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" label-width="40px" class="w-150">
              <el-select v-model="table.where.cid"
                         @change="handleChangeCity1(table.where.cid),$forceUpdate()" clearable>
                <el-option v-for="option in cityArr" :label='option.name' :value="option.cid"
                           :key="option.cid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="县:" label-width="40px" class="w-150">
              <el-select v-model="table.where.aid" @change="$forceUpdate()" clearable>
                <el-option v-for="option in districtArr" :label='option.name' :value="option.aid"
                           :key="option.aid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="管理员角色:" label-width="100px" class="w-200">
              <el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
                <el-option v-for="option in typeArr " :value="option.type" :key="option.type"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" label-width="60px" class="w-200 mr-20">
              <el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
                <el-option value="1" label="正常">正常</el-option>
                <el-option value="2" label="禁用">禁用</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                         class="ele-btn-icon">搜索
              </el-button>
              <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left"
                           show-overflow-tooltip/>
          <el-table-column prop="role_name" label="管理员权限" show-overflow-tooltip min-width="120px"/>
          <el-table-column prop="type" label="管理员角色" show-overflow-tooltip min-width='120px'>
            <template slot-scope="scope">
              {{ scope.row.type == 1 ? '总管理' : scope.row.type == 2 ? '省代理' : scope.row.type == 3 ? '市代理' : scope.row.type == 4 ? '县运营' : '广告商' }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="所辖地区" show-overflow-tooltip min-width="220px"/>
          <el-table-column prop="last_login_ip" label="上次登录ip" show-overflow-tooltip min-width="120px"/>
          <el-table-column prop="login_num" label="登录次数" show-overflow-tooltip/>
          <el-table-column label="上次登录时间" show-overflow-tooltip min-width='150px'>
            <template slot-scope="{row}">{{ row.last_login_time * 1000 | toDateString }}</template>
          </el-table-column>

          <el-table-column prop="real_name" label="真实姓名" show-overflow-tooltip/>
          <el-table-column prop="mobile" label="联系方式" show-overflow-tooltip min-width="120"/>
          <!-- <el-table-column prop="gender_name" label="性别" show-overflow-tooltip /> -->
          <el-table-column label="添加时间" show-overflow-tooltip min-width='150px'>
            <!-- <template slot-scope="{row}">{{ row.activation_time * 1000 | toDateString }}</template> -->
            <template slot-scope="{row}">{{ row.create_time  | toDateString }}</template>
          </el-table-column>
          <el-table-column label="状态" show-overflow-tooltip>
            <template slot-scope="{row}">
              <span v-if="row.status == 1">正常</span>
              <span v-else>禁用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right"
                           v-if="permission.includes('sys:admin:edit')">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
                       v-if="permission.includes('sys:admin:edit')">编辑
              </el-link>
              <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger"
                       :underline="false" v-if="permission.includes('sys:admin:delete') && row.id !== 1">删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑管理员':'添加管理员'" :visible.sync="showEdit" @closed="editForm={allow_ips:[]}"
               :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="200px">
        <el-row :gutter="15">
          <el-col :sm="12">
            <!-- 总管理 -->
            <el-form-item label="选择角色：" prop="type" v-if="editForm.id !== 1 && typeActive == 1">
              <el-select v-model="editForm.type" placeholder="" class="ele-block" clearable>
                <el-option v-for="option in typeArr" :value="option.type" :key="option.type"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- 省代理 -->
            <el-form-item label="选择角色：" prop="type" v-if="editForm.id !== 1 && typeActive == 2">
              <el-select v-model="editForm.type" placeholder="" class="ele-block" clearable>
                <el-option v-for="option in typeArrP" :value="option.type" :key="option.type"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- 市代理 -->
            <el-form-item label="选择角色：" prop="type" v-if="editForm.id !== 1 && typeActive == 3">
              <el-select v-model="editForm.type" placeholder="" class="ele-block" clearable>
                <el-option v-for="option in typeArrC" :value="option.type" :key="option.type"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- 县运营 -->
            <el-form-item label="选择角色：" prop="type" v-if="editForm.id !== 1 && typeActive == 4">
              <el-select v-model="editForm.type" placeholder="" class="ele-block" clearable>
                <el-option v-for="option in typeArrA" :value="option.type" :key="option.type"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- 广告商 -->
            <el-form-item label="选择角色：" prop="type" v-if="editForm.id !== 1 && typeActive == 5">
              <el-select v-model="editForm.type" placeholder="" class="ele-block" clearable>
                <el-option v-for="option in typeArrAd" :value="option.type" :key="option.type"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="匹配权限：" prop="role_name" v-if="editForm.id !== 1">
              <el-select v-model="editForm.role_name" placeholder="" class="ele-block" clearable
                         @change="handleRole">
                <el-option v-for="option in roleList" :value="option.id" :key="option.id"
                           :label="option.name"></el-option>
              </el-select>
            </el-form-item>

            <!-- <div v-if="!editForm.id"> -->
            <div>
              <el-form-item label="选择省：" v-show="pnameType && !cnameType">
                <el-select v-model="editForm.edit_pname" @change="handleChangeProv"
                           class="ele-block" clearable filterable multiple>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                             :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择省：" v-show="pnameType && cnameType">
                <el-select v-model="editForm.pname" @change="handleChangeProv"
                           class="ele-block" clearable filterable>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                             :label="option.name"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="选择市：" v-show="cnameType && !anameType">
                <el-select v-model="editForm.edit_cname" @change="handleChangeCity"
                           class="ele-block" clearable filterable multiple>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                             :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择市：" v-show="cnameType && anameType">
                <el-select v-model="editForm.cname" @change="handleChangeCity"
                           class="ele-block" clearable filterable>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                             :label="option.name"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="选择地区：" v-show="anameType">
                <el-select v-model="editForm.edit_aname" @change="handleDis" class="ele-block"
                           clearable filterable multiple>
                  <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                             :label="option.name"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="授权姓名：" prop="real_name">
              <el-input v-model="editForm.real_name" placeholder="请输入用户姓名" clearable/>
            </el-form-item>
            <el-form-item label="手机号码(登录账号)：" prop="mobile">
              <el-input v-model="editForm.mobile" maxlength="11" placeholder="请输入手机号" clearable/>
            </el-form-item>
            <el-form-item label="常用IP地址：" prop="allow_ip">
              <div style="display: flex">
                <el-input v-model="editForm.allow_ip"  placeholder="请输入常用IP地址(必填)" clearable/>
                <el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" @click="addIP" circle></el-button>
              </div>
            </el-form-item>
            <el-form-item v-for="(allow_ips_val, index) in editForm.allow_ips"  :key="index">
                <div style="display: flex">
                  <el-input  v-model="editForm.allow_ips[index]"  placeholder="请输入常用IP地址(备用选项)" clearable/>
                  <el-button  style="margin-left: 10px" type="danger" icon="el-icon-delete" @click="delIP(index)" circle></el-button>
                </div>
            </el-form-item>

            <el-form-item label="是否启用：" prop="status" v-if="editForm.id !== 1">
              <template>
                <el-radio v-model="editForm.status" :label="1">正常</el-radio>
                <el-radio v-model="editForm.status" :label="2">禁用</el-radio>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SysUser',
  data () {
    return {
      table: {
        url: '/admin/get_check_list',
        where: {}
      }, // 表格配置
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editForm: {
        allow_ips:[]
      }, // 表单数据
      editRules: { // 表单验证规则
        type: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }],
        role_name: [{
          required: true,
          message: '请选择匹配权限',
          trigger: 'blur'
        }],
        pid: [{
          required: true,
          message: '请选择省份',
          trigger: 'change'
        }],
        real_name: [{
          required: true,
          message: '请输入授权姓名',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
          trigger: 'blur',
        }],
        status: [{
          required: true,
          message: '请选择是否启用',
          trigger: 'blur'
        }],
        allow_ip: [{
          required: true,
          message: '请填写常用ip地址',
          trigger: 'blur'
        }]
      },
      roleList: [], // 角色列表
      levelList: [], // 职级列表
      positionList: [], // 岗位列表
      pnameType: true,//省输入框显示隐藏
      cnameType: true,//市输入框显示隐藏
      anameType: true,//区输入框显示隐藏
      provArr: [],
      cityArr: [],
      districtArr: [],
      typeActive: {},
      typeArrAd: [{
        type: 5,
        name: '广告商'
      }],
      typeArrA: [{
        type: 5,
        name: '广告商'
      }, {
        type: 4,
        name: '县运营'
      }],
      typeArrC: [{
        type: 3,
        name: '市代理'
      }, {
        type: 5,
        name: '广告商'
      }, {
        type: 4,
        name: '县运营'
      }],
      typeArrP: [{
        type: 2,
        name: '省代理'
      }, {
        type: 3,
        name: '市代理'
      }, {
        type: 5,
        name: '广告商'
      }, {
        type: 4,
        name: '县运营'
      }],
      typeArr: [{
        type: 1,
        name: '总管理'
      }, {
        type: 2,
        name: '省代理'
      }, {
        type: 3,
        name: '市代理'
      }, {
        type: 5,
        name: '广告商'
      }, {
        type: 4,
        name: '县运营'
      }],
      isCheckList: [{
        type: 1,
        name: '已审核'
      }, {
        type: 2,
        name: '已审核'
      }, {
        type: 3,
        name: '已驳回'
      }],
      xiu1: false,
      xiu2: false,
      xiu3: false,
      roleId: '',
      rolexiu: false,
    }
  },
  watch: {
    // 监听选择角色
    'editForm.type' (val) {
      this.pnameType = true
      this.cnameType = true
      this.anameType = true
      // //console.log(newval);
      // 省代理
      if (val == 2) {
        this.cnameType = false
        this.anameType = false
      } else if (val == 3) {
        this.anameType = false
      } else if (val == 1) {
        this.pnameType = false
        this.cnameType = false
        this.anameType = false
      }
    }
  },
  mounted () {
    this.queryRoles() // 查询角色列表
    this.getLevelList() // 查询职级列表
    this.getPositionList() // 查询岗位列表
  },
  created () {
    this.$http.get('/common/province_list').then(res => {
      var data = JSON.parse(res.data)
      this.provArr = data
    })
    this.$http.get('/admin/get_check_list').then(res => {
      this.typeActive = res.data.type
    })

  },
  computed: {
    ...mapGetters(['permission']),
  },
  methods: {
    /**
     *选择省
     **/
    handleChangeProv (e) {
      if (this.cnameType) {
        /** 获取被选省份的pid**/
        let pid = ''
        this.xiu1 = true
        this.provArr.forEach(function (item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        this.editForm.pname = pid
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
        })
      } else {
        var editpid = []
        this.xiu1 = true
        // 多选
        this.provArr.forEach(function (item) {
          e.forEach(function (i) {
            if (item.pid == i || item.name == i) {
              editpid.push(item.pid)
            }
          })
        })
        this.editForm.pname = editpid
      }

      /** 选择省份清空市县**/
      if (this.showEdit) {
        this.editForm.city_id = ''
        this.editForm.area_id = ''
        this.editForm.cname = ''
        this.editForm.aname = ''
        this.editForm.edit_cname = ''
        this.editForm.edit_aname = ''
      } else {
        this.table.where.cid = ''
        this.table.where.aid = ''
      }

    },
    /**
     *选择市
     **/
    handleChangeCity (e) {
      if (this.anameType) {
        if (e) {
          /** 获取被选市的cid**/
          let cid = ''
          this.xiu2 = true
          this.cityArr.forEach(function (item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
          })
        } else {
          this.table.where.pid = ''
          this.table.where.aid = ''
        }
      } else {
        var editcid = []
        this.xiu2 = true
        // 多选
        this.cityArr.forEach(function (item) {
          e.forEach(function (i) {
            if (item.cid == i || item.name == i) {
              editcid.push(item.cid)
            }
          })
        })
        this.$set(this.editForm, this.editForm.cname, editcid)
        this.editForm.cname = editcid
      }
      /** 选择省份清空县**/
      if (this.showEdit) {
        this.editForm.area_id = ''
        this.editForm.aname = ''
        this.editForm.edit_aname = ''
      } else {
        this.table.where.aid = ''
      }

    },
    /**
     *选择省
     **/
    handleChangeProv1 (e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        if (this.showEdit) {
          this.editForm.city_id = ''
          this.editForm.area_id = ''
          this.editForm.cname = ''
          this.editForm.aname = ''
        } else {
          this.table.where.cid = ''
          this.table.where.aid = ''
        }
      })
    },
    /**
     *选择市
     **/
    handleChangeCity1 (e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          if (this.showEdit) {
            this.editForm.area_id = ''
            this.editForm.aname = ''
          } else {
            this.table.where.aid = ''
          }
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },
    handleDis (e) {
      this.xiu3 = true
      //console.log(e)

      var editaid = []
      // 多选
      this.districtArr.forEach(function (item) {
        e.forEach(function (i) {
          if (item.aid == i || item.name == i) {
            editaid.push(item.aid)
          }
        })
      })
      //console.log(editaid)
      this.$set(this.editForm, this.editForm.edit_aname, editaid)
      this.editForm.aname = editaid
      this.editForm.edit_aname = editaid
    },
    handleRole () {
      this.rolexiu = true
    },
    /* 显示编辑 */
    edit (row) {
      this.showEdit = true
      this.editForm = row

      if (this.editForm.type >= 3) {
        this.$http.post('/common/city_list', {
          pid: this.editForm.province_id
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
        })
      }
      if (this.editForm.type >= 4) {
        this.$http.post('/common/area_list', {
          cid: this.editForm.city_id
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
        })
      }
    },
    /* 保存编辑 */
    save () {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })
          if (this.xiu1 == true) {
            this.editForm.province_id = this.editForm.pname
            this.xiu1 = false
          }
          if (this.xiu2 == true) {
            this.editForm.city_id = this.editForm.cname
            this.xiu2 = false
          }
          if (this.xiu3 == true) {
            this.editForm.area_id = this.editForm.aname
            this.xiu3 = false
          }
          // //console.log(this.editForm);return;
          if (this.rolexiu == true) {
            this.editForm.role_id = this.editForm.role_name
            this.rolexiu = false
          }
          this.$http.post('/admin/edit', this.editForm).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.showEdit = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        } else {
          return false
        }
      })
    },
    /* 删除 */
    remove (row) {
      if (!row) { // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据')
        let ids = this.choose.map(d => d.id)
        this.$confirm('确定要删除选中的管理员吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/admin/delete', {
            id: ids
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => 0)
      } else { // 单个删除
        this.$confirm('确定要删除选中的管理员吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/admin/delete', {
            id: row.id
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      }
    },
    /**
     * 重置密码
     */
    resetPwd (row) {
      const loading = this.$loading({
        lock: true
      })
      this.$http.post('/admin/resetPwd', {
        id: row.id
      }).then(res => {
        loading.close()
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.$refs.table.reload()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        loading.close()
        this.$message.error(e.message)
      })
    },
    /* 更改状态 */
    editStatus (row) {
      const loading = this.$loading({
        lock: true
      })
      let params = Object.assign({}, row)
      this.$http.post('/admin/status', params).then(res => {
        loading.close()
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
        } else {
          row.status = !row.status ? 2 : 1
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        loading.close()
        this.$message.error(e.message)
      })
    },
    /* 查询角色列表 */
    queryRoles () {
      this.$http.get('/role/getRoleList').then(res => {
        if (res.data.code === 0) {
          this.roleList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    /**
     * 获取职级列表
     */
    getLevelList () {
      this.$http.get('/level/getLevelList').then(res => {
        if (res.data.code === 0) {
          this.levelList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    /**
     * 获取岗位列表
     */
    getPositionList () {
      this.$http.get('/position/getPositionList').then(res => {
        if (res.data.code === 0) {
          this.positionList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    addIP(){
      if(!this.editForm.allow_ips){ // 如果allow_ips为空
        this.editForm.allow_ips = [] // 初始化allow_ips
      }
      if(this.editForm.allow_ips.length >= 4){ // 如果allow_ips最多4个
        this.$message.error("最多添加五个IP地址")
        return
      }
      this.editForm.allow_ips.push("") // 添加一个空IP地址
    },
    delIP(index){
      this.editForm.allow_ips.splice(index,1) // 删除指定下标的IP地址
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload,
.ele-block >>> .el-upload-dragger {
  width: 100%;
}

/* 用户资料卡片 */
.user-info-card {
  padding-top: 8px;
  text-align: center;
}

.user-info-card .user-info-avatar-group {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.user-info-card .user-info-avatar {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info-card .user-info-avatar-group > i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  font-size: 30px;
  display: none;
  z-index: 2;
}

.user-info-card .user-info-avatar-group:hover > i {
  display: block;
}

.user-info-card .user-info-avatar-group:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
}
</style>


<!--<template>-->
<!--  <div class="ele-body">-->
<!--    <el-card shadow="never">-->
<!--      &lt;!&ndash; 搜索表单 &ndash;&gt;-->
<!--      <el-form :model="table.where" label-width="77px" class="ele-form-search"-->
<!--               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>-->
<!--        <el-row :gutter="15">-->
<!--          <el-col :md="6" :sm="12">-->
<!--            <div class="ele-form-actions">-->
<!--              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">查询-->
<!--              </el-button>-->
<!--              <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form>-->
<!--      &lt;!&ndash; 操作按钮 &ndash;&gt;-->
<!--      <div class="ele-table-tool ele-table-tool-default">-->
<!--        <el-button @click="showEdit=true" type="primary" icon="el-icon-plus" class="ele-btn-icon" size="small" v-if="permission.includes('sys:admin:add')">添加-->
<!--        </el-button>-->
<!--        <el-button @click="remove()" type="danger" icon="el-icon-delete" class="ele-btn-icon" size="small" v-if="permission.includes('sys:admin:dall')">批量删除-->
<!--        </el-button>-->
<!--      </div>-->
<!--      &lt;!&ndash; 数据表格 &ndash;&gt;-->
<!--      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row>-->
<!--        <template slot-scope="{index}">-->
<!--          <el-table-column type="selection" width="45" align="center" fixed="left"/>-->
<!--          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>-->

<!--          <el-table-column prop="user_name" label="用户名" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="password" label="密码" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="salt" label="" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="login_times" label="登陆次数" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="last_login_ip" label="最后登录IP" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="last_login_time" label="最后登录时间" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--          <el-table-column prop="real_name" label="真实姓名" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="status" label="状态" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="role_id" label="用户角色id" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->
<!--          <el-table-column prop="role_name" label="用户角色" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="type" label="用户类型" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="leader" label="//负责人姓名" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--          <el-table-column prop="telephone" label="//联系电话" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="province_id" label="" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="city_id" label="" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="area_id" label="" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--          <el-table-column prop="is_check" label="//是否审核" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="effective_time" label="有效时间" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="num" label="可开通县城数" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="snum" label="已分配数量" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="balance" label="账户余额" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="withdrawal" label="提现金额" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="card" label="银行卡号" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="zt" label="1正常0冻结" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="card_name" label="持卡人姓名" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="card_phone" label="持卡人手机号" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="sms_num" label="短信条数" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="voice_num" label="语音分钟数" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="voice_num_buy" label="充值金额（秒数）" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="user_name" label="用户名" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="password" label="密码" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--          <el-table-column prop="salt" label="" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="login_times" label="登陆次数" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="last_login_ip" label="最后登录IP" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="last_login_time" label="最后登录时间" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="real_name" label="真实姓名" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="status" label="状态" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="role_id" label="用户角色id" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="type" label="1总后台2省代3市代4代理商5县代理" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="leader" label="负责人姓名" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="telephone" label="联系电话" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="province_id" label="" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="city_id" label="" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="area_id" label="" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="is_check" label="是否审核" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="effective_time" label="有效时间" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="num" label="可开通县城数" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="snum" label="已分配数量" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="balance" label="账户余额" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="withdrawal" label="提现金额" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--          <el-table-column prop="card" label="银行卡号" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--          <el-table-column prop="zt" label="1正常0冻结" sortable="custom" show-overflow-tooltip min-width="120"/>-->

<!--&lt;!&ndash;          <el-table-column prop="card_name" label="持卡人姓名" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="card_phone" label="持卡人手机号" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="sms_num" label="短信条数" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="voice_num" label="语音分钟数" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--&lt;!&ndash;          <el-table-column prop="voice_num_buy" label="充值金额（秒数）" sortable="custom" show-overflow-tooltip min-width="120"/>&ndash;&gt;-->

<!--          <el-table-column label="创建时间" sortable="custom" show-overflow-tooltip min-width="160">-->
<!--            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="更新时间" sortable="custom" show-overflow-tooltip min-width="160">-->
<!--            <template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="操作" width="130px" align="center" :resizable="false"  fixed="right">-->
<!--            <template slot-scope="{row}">-->
<!--              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:admin:edit')">修改</el-link>-->
<!--              <el-popconfirm title="确定要删除此后台员吗？" @confirm="remove(row)" class="ele-action">-->
<!--                <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:admin:delete')">删除</el-link>-->
<!--              </el-popconfirm>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </template>-->
<!--      </ele-data-table>-->
<!--    </el-card>-->
<!--    &lt;!&ndash; 编辑弹窗 &ndash;&gt;-->
<!--    <el-dialog v-dialogDrag :title="editForm.id?'修改后台员':'修改后台员'" :visible.sync="showEdit" width="450px"-->
<!--               @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">-->
<!--      <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">-->

<!--        <el-form-item label="用户名：" prop="user_name">-->
<!--          <el-input v-model="editForm.user_name" placeholder="请输入用户名" clearable/>-->
<!--        </el-form-item>-->

<!--&lt;!&ndash;        <el-form-item label="密码：" prop="password">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input v-model="editForm.password" placeholder="请输入密码" clearable/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="：" prop="salt">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.salt" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="登陆次数：" prop="login_times">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.login_times" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入登陆次数" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="最后登录IP：" prop="last_login_ip">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input v-model="editForm.last_login_ip" placeholder="请输入最后登录IP" clearable/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="最后登录时间：" prop="last_login_time">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.last_login_time" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入最后登录时间" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--        <el-form-item label="真实姓名：" prop="real_name">-->
<!--          <el-input v-model="editForm.real_name" placeholder="请输入真实姓名" clearable/>-->
<!--        </el-form-item>-->

<!--&lt;!&ndash;        <el-form-item label="状态：" prop="status">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.status" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入状态" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="用户角色id：" prop="role_id">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.role_id" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入用户角色id" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="//1总后台2省代3市代4代理商5县代理：" prop="type">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.type" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入//1总后台2省代3市代4代理商5县代理" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--        <el-form-item label="负责人姓名：" prop="leader">-->
<!--          <el-input v-model="editForm.leader" placeholder="请输入//负责人姓名" clearable/>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="联系电话：" prop="telephone">-->
<!--          <el-input v-model="editForm.telephone" placeholder="请输入//联系电话" clearable/>-->
<!--        </el-form-item>-->

<!--&lt;!&ndash;        <el-form-item label="：" prop="province_id">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.province_id" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="：" prop="city_id">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.city_id" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="：" prop="area_id">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.area_id" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="//是否审核：" prop="is_check">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.is_check" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入//是否审核" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="有效时间：" prop="effective_time">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.effective_time" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入有效时间" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="可开通县城数：" prop="num">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.num" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入可开通县城数" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="已分配数量：" prop="snum">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.snum" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入已分配数量" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="账户余额：" prop="balance">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.balance" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入账户余额" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="提现金额：" prop="withdrawal">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.withdrawal" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入提现金额" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--        <el-form-item label="银行卡号：" prop="card">-->
<!--          <el-input v-model="editForm.card" placeholder="请输入银行卡号" clearable/>-->
<!--        </el-form-item>-->

<!--&lt;!&ndash;        <el-form-item label="1正常0冻结：" prop="zt">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.zt" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入1正常0冻结" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="持卡人姓名：" prop="card_name">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input v-model="editForm.card_name" placeholder="请输入持卡人姓名" clearable/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="持卡人手机号：" prop="card_phone">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input v-model="editForm.card_phone" placeholder="请输入持卡人手机号" clearable/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="短信条数：" prop="sms_num">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.sms_num" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入短信条数" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="语音分钟数：" prop="voice_num">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.voice_num" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入语音分钟数" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <el-form-item label="充值金额（秒数）：" prop="voice_num_buy">&ndash;&gt;-->
<!--&lt;!&ndash;          <el-input-number v-model="editForm.voice_num_buy" controls-position="right" :min="0"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="请输入充值金额（秒数）" class="ele-fluid ele-text-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;        </el-form-item>&ndash;&gt;-->


<!--      </el-form>-->
<!--      <div slot="footer">-->
<!--        <el-button @click="showEdit=false">取消</el-button>-->
<!--        <el-button type="primary" @click="save">保存</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import { mapGetters } from "vuex";-->
<!--export default {-->
<!--  name: "SysAdmin",-->
<!--  data() {-->
<!--    return {-->
<!--      table: {url: '/admin/index', where: {}},  // 表格配置-->
<!--      choose: [],  // 表格选中数据-->
<!--      showEdit: false,  // 是否显示表单弹窗-->
<!--      editForm: {},  // 表单数据-->
<!--      editRules: {  // 表单验证规则-->

<!--        user_name: [-->
<!--          {required: true, message: '请输入用户名', trigger: 'blur'}-->
<!--        ],-->

<!--        // password: [-->
<!--        //   {required: true, message: '请输入密码', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // salt: [-->
<!--        //   {required: true, message: '请输入', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // login_times: [-->
<!--        //   {required: true, message: '请输入登陆次数', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // last_login_ip: [-->
<!--        //   {required: true, message: '请输入最后登录IP', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // last_login_time: [-->
<!--        //   {required: true, message: '请输入最后登录时间', trigger: 'blur'}-->
<!--        // ],-->

<!--        real_name: [-->
<!--          {required: true, message: '请输入真实姓名', trigger: 'blur'}-->
<!--        ],-->

<!--        // status: [-->
<!--        //   {required: true, message: '请输入状态', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // role_id: [-->
<!--        //   {required: true, message: '请输入用户角色id', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // type: [-->
<!--        //   {required: true, message: '请输入//1总后台2省代3市代4代理商5县代理', trigger: 'blur'}-->
<!--        // ],-->

<!--        leader: [-->
<!--          {required: true, message: '请输入//负责人姓名', trigger: 'blur'}-->
<!--        ],-->

<!--        telephone: [-->
<!--          {required: true, message: '请输入//联系电话', trigger: 'blur'}-->
<!--        ],-->

<!--        // province_id: [-->
<!--        //   {required: true, message: '请输入', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // city_id: [-->
<!--        //   {required: true, message: '请输入', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // area_id: [-->
<!--        //   {required: true, message: '请输入', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // is_check: [-->
<!--        //   {required: true, message: '请输入//是否审核', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // effective_time: [-->
<!--        //   {required: true, message: '请输入有效时间', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // num: [-->
<!--        //   {required: true, message: '请输入可开通县城数', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // snum: [-->
<!--        //   {required: true, message: '请输入已分配数量', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // balance: [-->
<!--        //   {required: true, message: '请输入账户余额', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // withdrawal: [-->
<!--        //   {required: true, message: '请输入提现金额', trigger: 'blur'}-->
<!--        // ],-->

<!--        card: [-->
<!--          {required: true, message: '请输入银行卡号', trigger: 'blur'}-->
<!--        ],-->

<!--        // zt: [-->
<!--        //   {required: true, message: '请输入1正常0冻结', trigger: 'blur'}-->
<!--        // ],-->

<!--        // card_name: [-->
<!--        //   {required: true, message: '请输入持卡人姓名', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // card_phone: [-->
<!--        //   {required: true, message: '请输入持卡人手机号', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // sms_num: [-->
<!--        //   {required: true, message: '请输入短信条数', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // voice_num: [-->
<!--        //   {required: true, message: '请输入语音分钟数', trigger: 'blur'}-->
<!--        // ],-->
<!--        //-->
<!--        // voice_num_buy: [-->
<!--        //   {required: true, message: '请输入充值金额（秒数）', trigger: 'blur'}-->
<!--        // ],-->


<!--      },-->
<!--    }-->
<!--  },-->
<!--  computed: {-->
<!--    ...mapGetters(["permission"]),-->
<!--  },-->
<!--  mounted() {-->
<!--  },-->
<!--  methods: {-->
<!--    /**-->
<!--     * 显示编辑-->
<!--     */-->
<!--    edit(row) {-->
<!--      this.$http.get('/admin/info?id=' + row.id).then(res => {-->
<!--        if (res.data.code === 0) {-->
<!--          this.editForm = res.data.data;-->
<!--          this.showEdit = true;-->
<!--        } else {-->
<!--          this.$message.error(res.data.msg);-->
<!--        }-->
<!--      }).catch(e => {-->
<!--        this.$message.error(e.message);-->
<!--      });-->
<!--    },-->
<!--    /**-->
<!--     * 保存编辑-->
<!--     */-->
<!--    save() {-->
<!--      this.$refs['editForm'].validate((valid) => {-->
<!--        if (valid) {-->
<!--          const loading = this.$loading({lock: true});-->
<!--          this.$http.post('/admin/edit', this.editForm).then(res => {-->
<!--            loading.close();-->
<!--            if (res.data.code === 0) {-->
<!--              this.showEdit = false;-->
<!--              this.$message({type: 'success', message: res.data.msg});-->
<!--              this.$refs.table.reload();-->
<!--            } else {-->
<!--              this.$message.error(res.data.msg);-->
<!--            }-->
<!--          }).catch(e => {-->
<!--            loading.close();-->
<!--            this.$message.error(e.message);-->
<!--          });-->
<!--        } else {-->
<!--          return false;-->
<!--        }-->
<!--      });-->
<!--    },-->
<!--    /**-->
<!--     * 刪除(批量刪除)-->
<!--     */-->
<!--    remove(row) {-->
<!--      //console.log(row)-->
<!--      if (!row) {  // 批量删除-->
<!--        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');-->
<!--        let ids = this.choose.map(d => d.id);-->
<!--        this.$confirm('确定要删除选中的后台员吗?', '提示', {type: 'warning'}).then(() => {-->
<!--          const loading = this.$loading({lock: true});-->
<!--          this.$http.post('/admin/delete', {id: ids}).then(res => {-->
<!--            loading.close();-->
<!--            if (res.data.code === 0) {-->
<!--              this.$message({type: 'success', message: res.data.msg});-->
<!--              this.$refs.table.reload();-->
<!--            } else {-->
<!--              this.$message.error(res.data.msg);-->
<!--            }-->
<!--          }).catch(e => {-->
<!--            loading.close();-->
<!--            this.$message.error(e.message);-->
<!--          });-->
<!--        }).catch(() => 0);-->
<!--      } else {  // 单个删除-->
<!--        const loading = this.$loading({lock: true});-->
<!--        this.$http.post('/admin/delete', {id:row.id}).then(res => {-->
<!--          loading.close();-->
<!--          if (res.data.code === 0) {-->
<!--            this.$message({type: 'success', message: res.data.msg});-->
<!--            this.$refs.table.reload();-->
<!--          } else {-->
<!--            this.$message.error(res.data.msg);-->
<!--          }-->
<!--        }).catch(e => {-->
<!--          loading.close();-->
<!--          this.$message.error(e.message);-->
<!--        });-->
<!--      }-->
<!--    },-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {-->
<!--  width: 100%;-->
<!--}-->
<!--</style>-->
